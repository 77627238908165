define("ember-google-maps/utils/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toLatLng = toLatLng;

  function toLatLng(lat, lng) {
    var _google;

    return lat && lng && (_google = google) !== null && _google !== void 0 && _google.maps ? new google.maps.LatLng(lat, lng) : undefined;
  }
});