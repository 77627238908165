define('ember-in-viewport/services/-in-viewport', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  var _get = function get(object, property, receiver) {
    if (object === null) object = Function.prototype;
    var desc = Object.getOwnPropertyDescriptor(object, property);

    if (desc === undefined) {
      var parent = Object.getPrototypeOf(object);

      if (parent === null) {
        return undefined;
      } else {
        return get(parent, property, receiver);
      }
    } else if ("value" in desc) {
      return desc.value;
    } else {
      var getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return getter.call(receiver);
    }
  };

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var rAFPoolManager = function (_Ember$Service) {
    _inherits(rAFPoolManager, _Ember$Service);

    function rAFPoolManager() {
      _classCallCheck(this, rAFPoolManager);

      return _possibleConstructorReturn(this, (rAFPoolManager.__proto__ || Object.getPrototypeOf(rAFPoolManager)).apply(this, arguments));
    }

    _createClass(rAFPoolManager, [{
      key: 'init',
      value: function init() {
        var _get2;

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        (_get2 = _get(rAFPoolManager.prototype.__proto__ || Object.getPrototypeOf(rAFPoolManager.prototype), 'init', this)).call.apply(_get2, [this].concat(args));
        this.pool = [];
        this.flush();
      }
    }, {
      key: 'flush',
      value: function flush() {
        var _this2 = this;

        window.requestAnimationFrame(function () {
          // assign to a variable to avoid ensure no race conditions happen
          // b/w flushing the pool and interating through the pool
          var pool = _this2.pool;
          _this2.reset();
          pool.forEach(function (item) {
            item[Object.keys(item)[0]]();
          });
          _this2.flush();
        });
      }
    }, {
      key: 'add',
      value: function add(elementId, fn) {
        this.pool.push(_defineProperty({}, elementId, fn));
        return fn;
      }
    }, {
      key: 'remove',
      value: function remove(elementId) {
        this.pool = this.pool.filter(function (obj) {
          return !obj[elementId];
        });
      }
    }, {
      key: 'reset',
      value: function reset() {
        this.pool = [];
      }
    }]);

    return rAFPoolManager;
  }(Ember.Service);

  exports.default = rAFPoolManager;
});